import { navigate } from 'gatsby';
import { window } from 'browser-monads';
import React, { Fragment, useEffect } from 'react';

import s from './index.module.scss';
import step3 from '../../../../static/steps/step3.png';
import { useWindowDimensions } from '../../../hooks/useDimension';

import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import Button from '../../../components/button/index';
import Seo from '../../../components/Seo';

import { pay } from '../../../api/payment';
import { checkUser } from '../../../api/auth';
import { updateReservation } from '../../../api/reservations';

const Pay = ({ location }) => {
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    if (!location.state?.newReservation) return navigate('/experiences');
  }, [location]);

  useEffect(() => {
    checkUser()
      .then((res) => {
        if (!res) {
          return navigate('/home');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const newReservation = location.state?.newReservation;

  const onSubmit = (e) => {
    e.preventDefault();

    pay(newReservation)
      .then((response) => {
        console.log(response);

        if (response.ok) {
          response.json().then((data) => {
            console.log(data);

            updateReservation(newReservation.id, data.link);
            window.open(data.link, '_self');
          });
        } else {
          console.error('Error consultando los datos. \n', response);
          navigate('/404');
        }
      })
      .catch((err) => {
        console.log(err);
        navigate('/404');
      });
  };

  return (
    <Fragment>
      <Seo lang="es" description="Pagar la experiencia" title="Pagar" />
      <Header step={step3} step3={true} />
      <div className={s.container}>
        <div className={s.box}>
          <div className={s.content}>
            <p className={s.title1}>corona</p>
            <p className={`${s.title2} ${s.yellow}`}>mountain sessions</p>
            <p className={s.title3}>CAJÓN DEL MAIPO</p>

            <p className={s.text}>
              Disfruta un Corona Festival exclusivo para ti y tus amigos durante
              la tarde en una casa inmersa en el cerro con una vista
              espectacular a solo 20 minutos de Santiago con cervezas, música,
              DJ, comida y transporte.
            </p>

            <p className={s.title4}>Resumen de tu experiencia:</p>

            <p className={s.list}>
              Arriendo Casa Montaña desde 00:00h. a 00:00h.
              <br />
              Ambientacion, Loung, Iluminación, etc.
              <br />
              Traslado Ida y Vuelta.
              <br />
              DJ x 2 horas.
              <br />
              Barra Corona Drinks.
              <br />
              15 Amigos.
            </p>

            <div className={s.buttons}>
              <p className={s.total}>
                TOTAL ${newReservation && newReservation.total}
              </p>
              <Button
                type="submit"
                className="payButton"
                value="PAGAR"
                onClick={onSubmit}
              />
            </div>
          </div>
        </div>
        <Footer
          styles={{
            position: width <= 768 || height <= 750 ? 'static' : 'absolute',
          }}
        />
      </div>
    </Fragment>
  );
};

export default Pay;
